<template>
  <div class="filter-cards">
    <div
      v-for="(card, cardIdx) in cardData"
      :key="cardIdx"
      class="filter-card"
      :class="{'filter-card-active': cardIdx === currentlySelectedCardIdx}"
      @click="onCardClicked(cardIdx)"
    >
      <div class="filter-card-content">
        {{ card }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cardData: {
      type: Array,
      required: true,
    },
    currentlySelectedCardIdx: {
      type: Number,
      required: true,
    },
  },
  emits: ['cardClicked'],
  methods: {
    onCardClicked(cardIdx) {
      this.$emit('cardClicked', cardIdx);
    },
  },

};
</script>
<style lang="scss">
  .filter-cards {
    align-items: stretch;
    display: flex;
    gap: 16px;
  }

  .filter-card {
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    height: 100%;
    min-height: 86px;
    max-width: 168px;
  }
  .filter-card-content {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
  }
  .filter-card-active {
    background-color: #C1D0D1;
  }
</style>
