<template>
  <div class="kg-dashboard">
    <div class="main-container">
      <h1>Dashboard</h1>
    </div>
    <hr>
    <dashboard-insights />
  </div>
</template>
<script>

import DashboardInsights from '@/components/dashboard/DashboardInsights.vue';

export default {
  components: {
    DashboardInsights,
  },
};
</script>
