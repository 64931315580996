<template>
  <div class="main-container">
    <h2>Insights</h2>
    <filter-cards
      :card-data="['COVID (10 mentions)', 'ESG Regulations']"
      :currently-selected-card-idx="currentCardIdxSelected"
      @cardClicked="onFilterCardClicked"
    />
    <h5 class="kg-vis-title">
      View By NAV
    </h5>
    <div class="kg-vis">
      <div class="kg-fd-graph-container">
        <svg class="kg-fd-graph" />
      </div>
      <div class="kg-node-info-container">
        <h5 class="current-info-title">
          {{ currentInfo.title }}
        </h5>
        <p
          v-for="bodyItem, idx in currentInfo.body"
          :key="idx"
        >
          {{ bodyItem }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import FilterCards from '@/components/dashboard/FilterCards.vue';

export default {
  components: { FilterCards },
  data() {
    return {
      toast: useToast(),
      currentCardIdxSelected: 0,
      baseNodeSize: 20,
      baseNodeSizeBorder: 4,
      margin: {
        top: 20, right: 80, bottom: 30, left: 50,
      },
      widthProportion: 640,
      heightProportion: 480,
      width: 0,
      height: 0,
      currentInfo: {
        title: '',
        body: '',
      },
    };
  },
  mounted() {
    this.getInsights()
      .then((insightData) => {
        const { colours, edges, nodes } = insightData;
        this.initVis(colours, edges, nodes);
      });
  },
  methods: {
    ...mapActions({
      getDashboardInsights: 'dashboard/getInsights',
    }),
    async getInsights() {
      this.$log.info('Fetching insights for dashboard');
      return this.getDashboardInsights()
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching insights');
          throw e;
        });
    },
    initVis(colours, rawEdges, rawNodes) {
      this.currentInfo = {
        title: rawNodes[0].label,
        body: rawNodes[0].info,
      };
      this.width = this.widthProportion - this.margin.left - this.margin.right;
      this.height = this.heightProportion - this.margin.top - this.margin.bottom;
      const nodes = rawNodes.map((d) => Object.create(d));
      const edges = rawEdges.map((d) => Object.create(d));

      const boundingForce = () => {
        const maxBoundingRadius = this.getBaseNodeSize(4) + this.baseNodeSizeBorder + 1;
        const widthBound = this.width - maxBoundingRadius;
        const heightBound = this.height - maxBoundingRadius;
        for (const node of nodes) { // eslint-disable-line no-restricted-syntax
          node.x = Math.max(-widthBound, Math.min(widthBound, node.x));
          node.y = Math.max(-heightBound, Math.min(heightBound, node.y));
        }
      };

      const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(edges).id((d) => d.id).distance(40))
        .force('charge', d3.forceManyBody().strength(-600))
        .force('center', d3.forceCenter(this.width / 2, this.height / 2))
        .force('collide', d3.forceCollide(() => this.getBaseNodeSize(4) + this.baseNodeSizeBorder + 1))
        .force('bounds', boundingForce);

      this.$log.info('simulation:', simulation);
      const svg = d3.select('.kg-fd-graph').attr('viewBox', [0, 0, this.width, this.height]);

      // const color = (d) => {
      //   const scale = d3.scaleOrdinal(d3.schemeCategory10);
      //   return (d1) => scale(d1.group);
      // };
      const edgesContainer = svg.append('g').attr('class', 'edges');
      const nodesContainer = svg.append('g').attr('class', 'nodes')
        .attr('stroke', '#fff')
        .attr('stroke-width', 1.5);

      this.buildNodes(nodesContainer, edgesContainer, simulation, nodes, edges, colours);

      // const refresh = () => {
      //   simulation.alpha(0.5).alphaTarget(0.25).restart();
      //   const blankNodes = nodes.map((d) => Object.create(d));
      //   const blankEdges = this.edgeData.map((d) => Object.create(d));
      //   this.buildNodes(nodesContainer, edgesContainer, simulation, blankNodes, blankEdges);
      // };
      // svg.append('text').attr('transform', `translate(${2},${20})`).text('Delete node')
      //   .on('click', () => {
      //     this.$log.info('Delete node clicked...');
      //     nodes.splice(-1);
      //     this.$log.info('new nodes:', nodes);
      //     refresh();
      //   });
    },
    getBaseNodeSize(nodeSize) {
      return this.baseNodeSize + nodeSize * 4;
    },
    getBaseNodeSizeWithBorder(nodeSize) {
      return this.getBaseNodeSize(nodeSize) + this.baseNodeSizeBorder;
    },
    buildNodes(nodesContainer, edgesContainer, simulation, nodes, edges, colours) {
      const link = edgesContainer.append('g')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(edges)
        .join('line')
        .attr('stroke-width', (d) => Math.sqrt(d.value));

      const nodeContainer = nodesContainer
        .selectAll('g')
        .data(nodes, (d) => d.id);
      const nodeContainerEnter = nodeContainer.enter().append('g');
      nodeContainer.exit().remove();

      nodeContainerEnter
        .call(this.drag(simulation))
        .on('click', (e, d) => {
          this.$log.info('Node clicked', e, d);
          const currentNode = nodes[d.index];
          this.$log.info('Node clicked', currentNode);
          this.currentInfo.title = currentNode.label;
          this.currentInfo.body = currentNode.info;
        });

      nodeContainerEnter.append('circle')
        .attr('r', (d) => this.getBaseNodeSizeWithBorder(d.size))
        .attr('fill', '#D9D8D8').attr('stroke-width', 0);

      // Inner circle
      nodeContainerEnter.append('circle')
        .attr('class', 'node-inner-circle')
        .attr('r', (d) => this.getBaseNodeSize(d.size))
        .attr('fill', 'white')
        .attr('stroke-width', 0);
      // .attr('fill', color);

      // First Arc (could be parameterised to take n number of colours):
      nodeContainerEnter.append('path')
        .attr('fill', 'none').attr('stroke', colours[0])
        .attr('stroke-width', this.baseNodeSizeBorder / 2 + 2)
        .attr('d', (d) => this.describeArc(
          0,
          0,
          this.getBaseNodeSize(d.size) + this.baseNodeSizeBorder / 2,
          0,
          d.colourProportions[0] * 365,
        ));
      // Second arc
      nodeContainerEnter.append('path')
        .attr('fill', 'none').attr('stroke', colours[1])
        .attr('stroke-width', this.baseNodeSizeBorder / 2 + 2)
        .attr('d', (d) => {
          const startAngle = d.colourProportions[0] * 365;
          const endAngle = d.colourProportions[1] * 365 + startAngle;
          return this.describeArc(
            0,
            0,
            this.getBaseNodeSize(d.size) + this.baseNodeSizeBorder / 2,
            startAngle,
            endAngle,
          );
        });

      const nodeSizeToTextScale = (nodeSize) => {
        if (nodeSize > 2) {
          return 0.65;
        }
        if (nodeSize.size > 1) {
          return 0.6;
        }
        return 0.5;
      };

      const textMarginOffsetPerc = 0.9;
      const textTranslate = (s) => `translate(${-s}, ${-s})`;
      nodeContainerEnter.append('foreignObject')
        .attr('width', (d) => this.getBaseNodeSize(d.size) * 2 * textMarginOffsetPerc)
        .attr('height', (d) => this.getBaseNodeSize(d.size) * 2 * textMarginOffsetPerc)
        .attr('transform', (d) => textTranslate(this.getBaseNodeSize(d.size) * textMarginOffsetPerc))
        .append('xhtml:div')
        .attr('class', 'node-text-container')
        .append('span')
        .attr('class', 'node-text')
        .attr('style', (d) => `font-size: ${nodeSizeToTextScale(d.size)}em;`)
        .text((d) => d.label);

      simulation.on('tick', () => {
        link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y);

        // Commented code for managing node bounding within the tick function:
        // const boundedNodeX = (x, radius) => Math.max(radius, Math.min(this.width - radius, x));
        // const boundedNodeY = (y, radius) => Math.max(radius, Math.min(this.height - radius, y));
        // const translateX = boundedNodeX(d.x, this.getBaseNodeSizeWithBorder(d.size));
        // const translateY = boundedNodeY(d.y, this.getBaseNodeSizeWithBorder(d.size));
        // if (Number.isFinite(translateY) && Number.isFinite(translateX)) {
        //   return `translate(${translateX},${translateY})`;
        // }
        //
        // return 'translate(0,0)';
        nodeContainerEnter
          .attr('transform', (d) => `translate(${d.x},${d.y})`);
      });

      return nodeContainerEnter;
    },
    drag(sim) {
      function dragstarted(event) {
        const e = event;
        if (!event.active) sim.alphaTarget(0.3).restart();
        e.subject.fx = e.subject.x;
        e.subject.fy = e.subject.y;
      }

      function dragged(event) {
        const e = event;
        e.subject.fx = event.x;
        e.subject.fy = event.y;
      }

      function dragended(event) {
        const e = event;
        if (!event.active) sim.alphaTarget(0);
        e.subject.fx = null;
        e.subject.fy = null;
      }

      return d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
    },
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians)),
      };
    },

    describeArc(x, y, radius, startAngle, endAngle) {
      const start = this.polarToCartesian(x, y, radius, endAngle);
      const end = this.polarToCartesian(x, y, radius, startAngle);

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      return [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      ].join(' ');
    },
    onFilterCardClicked(cardIdx) {
      this.currentCardIdxSelected = cardIdx;
    },
  },
};
</script>
<style lang='scss'>

.kg-vis {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.kg-node-info-container {
  padding: 32px;
  width: 100%;
  flex-basis: 33%;
  background-color: #F7F7F7;
}

.kg-fd-graph-container {
  border: 1px solid #6fa6aa;
  width: 100%;
  max-height: 1400px;
}

.node-inner-circle {
  cursor: move;
}

.node-circle-text {
  fill: black;
  stroke: black;
  text-anchor: middle;
  alignment-baseline: middle;
  stroke-width: 0 !important;
  cursor: move;
}

.kg-vis-title {
  text-transform: unset;
  margin-bottom: 8px;
}

.current-info-title {
  text-transform: unset;
  font-weight: $bold;
  margin-top: 12px;
}

.node-text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.node-text {
  text-align: center;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  color: black;
  margin-top: auto;
  margin-bottom: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
